ul{
    padding-left: 0px;
}

body{
    font-size: 16px;
}

.no-header-img{
    padding-bottom: 200px;
}

#tinymce{
    ul, ol{
        padding-left: 30px;
    }
    ul{
        li{
            display: block;
            list-style-type: circle;
        }
        li::before{
            content: '\2022';
            padding-right: 10px;
        }
    }
}

.header-space-correct{
   margin-bottom: 40px;
    @media (max-width: $screen-xs){
        margin-bottom: 0px;
    }
}

.text-muted{
    margin-bottom: 10px;
    display: block;
}
.wp-editor-content{
    img{
        @media (max-width: $screen-md){
            display: block;
            max-width: 100%;
            margin: 0 auto;
            margin-bottom: 20px;
            margin-top: 20px;
            float: none;
        }
    }
    p{
        margin: 20px 0px;
    }
}

.hide{
    display: none;
}

.large-callout-group{
    margin-bottom: 50px;
}

.category-spacer{
    margin-top: 60px;
}