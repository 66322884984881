@mixin line-atts(){
  width: 100%;
  display: block;
  margin: 20px 0px;
  height: 2px;
  opacity: 0.3;
}

.line.blue{
  background-color: $brand-primary;
  @include line-atts();
}

.line.red{
  background-color: red;
  @include line-atts();
}

.line.orange{
  background-color: $orange;
  @include line-atts();
}

.blue-callout{
  background-color: $blue-light;
  padding: 20px;
  text-align:center;
  color: $brand-primary;
  width: 100%;
  display: block;
  margin: 10px 0px;
  font-size: 16px;
}