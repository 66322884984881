.serviceBox {
  margin-top: 5%;
  margin-bottom: 5%;
  color: #18315f;
}

.subServicesBox {
  width: 70%;
  margin: 0 auto;
  padding: 5% 0;
  border-bottom: 2px solid $blue-light;
  
    @media (max-width: $screen-sm){
        width: 100%;
    }

    .tag {
      padding-left: 5em;
    }
  
    .lead{
        margin-right: 50px;
    }
    .fa{
        margin-left: 15px;
    }
    
    .font-crimson{
        font-size: 16px;
    }
    
    .fa, .font-crimson{
        margin-bottom: 10px;
    }
    
    .chart-link-wrap{
        @media (max-width: $screen-xs){
            display: block;
            margin-top: 5px;
        }
    }
    
    .fa{
        margin-right: 5px;
        @media (max-width: $screen-sm){
            margin-left: 0px;
        }
    }
    
    .subService-bullet-wrap{
        display: inline-block;
        margin-bottom: 5px;

        @media (max-width: $screen-xs){
            display: block;
            margin-bottom: 0px;
        }
    }
}

.serviceBox-wrap{
    border-top: 2px solid $peach;
    border-bottom: 2px solid $blue-light;
    margin-top: 30px;
}

.moreinfo-container {
  margin-top: 4%;
  margin-bottom: 2%;
  padding: 30px 20% 30px 20%;
  background-color: #d8e4e7;
  @media (max-width: $screen-xs){
      padding: 30px 15px;
  }
}

.chart-wrap{
    margin: 20px;
    img{
        margin: 0 auto;
    }
    area:hover{
        cusor: pointer;
    }
}

.other-services-link{
  display: block;
  padding: 15px;
  text-align:center;
}