
nav.navbar {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;
  border: none;
  text-align:center;
  min-height: 0px;
  border-top: 3px solid $blue-medium;
  border-bottom: 3px solid $blue-medium;
  border-radius: 0px;

  #menuNav {
   margin-top: 18px;
  }

  .navbar-brand {
    display: none;
  }
  
  .navbar-collapse{
        @media (max-width: $grid-float-breakpoint){
            background-color: $brand-primary;
            color: $blue-light;
        }
  }
  
    .social{
        @include social-icons();
        display: none;
        text-align:center;
        width: 100%;
        marging: 10px;
        
        @media (max-width: $grid-float-breakpoint ){
            display: block;
        }
        
        .fa{
            margin: 10px;
            border: none;
        }
        a{
            margin: 0 auto;
            float: none;
        }
      
    }
    
    .language{
        display: none;
        width: 100%;
        float: none;
        text-align:center;
        margin-top: 30px;
        @media (max-width: $screen-xs){
            display: block;
        }
        #lang_sel_list{
            float: none;
            margin: 0 auto;
            ul{
                width: 100%;
                li{
                    float: none;
                    display: inline-block;
                    a{
                        text-transform:initial;
                    }       
                }
            }
            .lang_sel_sel{
                color: $white;
            }
        }
    }
  
    .menu{
        margin: 0 auto;
        padding-left: 0px;
        @media (max-width: $grid-float-breakpoint){
            width: 100%;
        }
        .sep{
            height: 20px;
            width: 1px;
            background-color: $blue-light;
            display: inline-block;
            margin-left: 20px;
            margin-bottom: -5px;
        }
        li{
            display: inline-block;
            padding: 20px 0px;
            text-align:center;
            list-style: none;
              @media (max-width: $grid-float-breakpoint){
                  display: block;
                  float: none;
                  padding: 15px 0px;
                  border-bottom: 1px solid $blue-border;
              }
              a{
                  color: $brand-primary;
                  @media (max-width: $grid-float-breakpoint){
                      color: $white;
                  }
              }
        }
        li:last-child{
            .sep{
                background-color: $white;
            }
        }
    }
   
    li.menu-item {
       text-transform: uppercase;
        font-weight: bold;
      
      ul.dropdown-menu {
        background-color: $brand-primary;
        margin-right: 5px;
        width: 249px;
        margin-top: -2px;
        
        @media (max-width: $grid-float-breakpoint){
            width: 100%;
            position: relative;
            margin: 0px;
            border: none;
            float: none;
            z-index: 11;
            box-shadow: none;
        }
        li:first-child > a:after {
          content: '';
          position: absolute;
          left: 50px;
          top: -10px;
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 12px solid #19315f;
          //z-index: -1;
          @media (max-width: $grid-float-breakpoint){
              display: none;
          }
        }
        li{
            display: block;
            float: none;
            width: 100%;
            text-align:center;
            border-bottom: none;
        }

        li, li.active {
          padding: 0;
          text-align: center;
          a, a:visited, a:link, a:active{
            border-right: none !important;
            color: white;
            background-color: transparent;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 15px;
            padding-right: 15px;
            font-size: 14px;
            border-bottom: 1px $blue-light;
            z-index: 20;
            @media (max-width: $grid-float-breakpoint){
                opacity: 0.8;
            }
          }
          a:hover {
            background-color: #d9e4e8;
            color: #19315f;
            text-transform: uppercase;
        
         }
        }
      }

      a {
        border-right: 1px solid #cdcdcd;
        line-height: 14px;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 17px;
        padding-top: 0;
        padding-bottom: 0;
        width: auto;
        word-wrap: break-word;
        display: list-item;
        white-space: normal;
        height: auto;
        letter-spacing: 1px;
        text-transform: uppercase;

        @media (max-width: $screen-lg ){
          font-size: 14px;
        }
        
        @media (max-width: $grid-float-breakpoint){
            border-right: none;

        }

        span.caret {
          color: #e5b494;
        }
      }
      a:hover, a:focus, a:visited {
        background: transparent;
      }
    }
    li.menu-item:last-child {
      a {
        border-right: none;
      }
    }
}

.navbar-header {
  display: none;
  @media (max-width: $grid-float-breakpoint){
      display: block;
  }
  @media (max-width: $screen-xs){
      background-color: $blue-light;
  }
  
  .button-wrap{
      text-align:right;
      float: right;
      max-width: 170px;
      .menu-toggle-label{
          display: inline-block;
          float: left;
          font-size: 20px;
          padding-top: 24px;
          @media (max-width: $screen-xs){
              display: none;
          }
      }
      .menu-label{
          display: none;
          @media(max-width: $screen-xs){
              display: block;
          }
      }
  }
  .navbar-toggle{
      margin: 15px;
      float: right;
      display:inline-block;
      float: right;
      @media (max-width: $screen-xs){
          margin-top: 0px;
      }
      span:first-child{
          margin-top: 0px;
      }
      .icon-bar{
          background-color: $brand-primary;
          width: 40px;
          height: 3px;
          margin-top: 5px;
      }
  }
  .brand{
      display: none;
      @media (max-width: $screen-xs){
          display: block;
      }
      img{
          max-height: 75px;
          margin: 0px;
      }
  }
}

hr.web {
  display: block;
  width: 100%;
}


.alignright {
  float: right !important;
  display: block;
}

.alignleft {
  float: left;
}


ul li {
  display: inline;
  list-style-type: none;
}

a, a:hover, a:visited{
  text-transform: uppercase;
  color: #3b506d;
  text-decoration: none;
}

.footer-image {
  display: inline;
  float: left;
  width: 60px;
  height: 60px;
}

.menu-triangle{
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent $brand-primary transparent;
    display: none;
    position: absolute;
    top: 65px;
    right: 35px;
    @media (max-width: $grid-float-breakpoint){
        display: block;
    }
    @media (max-width: $screen-xs){
        top: 70px;
        
    }
}

.navbar-collapse{
    border-top: none;
    padding-left: 0px;
    padding-right: 0px;
  .menu{
    display: inline-block;
  }
  .fa{
    font-size: 22px;
    border-left: 1px solid $gray-lighter;
    padding-left: 10px;
    @media (max-width: $grid-float-breakpoint ){
     // display: none;
    }
  }
}


.linkedin-nav{
  @media (max-width: $grid-float-breakpoint ){
    display: none;
  }
}
