// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

.ninja-forms-required-items{
  margin: 0px 0px 15px 15px;
}

.spam-q{
  margin-left: 10px;
  width: 45% !important;
}