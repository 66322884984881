.banner-wrapper {
  position: relative;

  .banner-box {
    width: 80%;
    background-color: #d8e4e7;
    margin-top: -3%;
    padding: 20px 40px 20px 40px;
    z-index: 1;
    @media (max-width: $screen-xs){
        width: 100%;
        margin: 0;
        padding: 15px 20px 15px 20px;
    }
    p{
      font-size: 16px;
      &.banner-header{
        font-size: 24px;
      }
      &.orange{
        font-size: 18px;
      }
      &.font-crimson{
        font-size: 18px;
      }
    }
  }
}

.box-description{
    font-size: 15px;
    max-width: 740px;
    @media (max-width: $screen-md){
        padding: 25px 10px;
    }
}

.banner-header {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: $brand-primary;
  font-size: 24px;
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 10px;
  margin-top: 0px;
}

.box-wrapper {
  margin-top: 5%;
  margin-bottom: 3%;
  position: relative;
  
  @media (max-width: $screen-md){
      margin-top: 50px;
  }


  .thin-border {
    border-style: solid;
    border-width: 1px;
    border-color: white;
    position: relative;
    padding: 2%;
    font-size: 18px;

  }

  .box-header {
    margin: 0 auto;
    margin-bottom: 3%;
    padding: 3px;
    background-color: #99a4b6;
    position: relative;
    z-index: 1;
    max-width: 300px;
    @media (max-width: $screen-xs){
      margin-bottom: 40px;
    }
  }

  .box-description {
    width: 50%;
    margin-bottom: 3%;
  }
}

.service-core-container {
  margin-top: 3%;
  margin-bottom: 3%;
  font-size: medium;
  padding: 4px;
  width: 50%;
  border-style: solid;
  border-width: 5px;
  border-color: #99a4b6;
  
  @media (max-width: $screen-sm){
      width: 65%;
  }

  @media (max-width: $screen-xs){
      width: 90%;
      margin-top: 30px;
      margin-bottom: 30px;
  }


  .thin-border {
    border-style: solid;
    border-width: 1px;
    border-color: #99a4b6;
    position: relative;
    padding-top: 3%;
    padding-bottom: 3%;
  }

  .service-core-item {
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 10%;
    text-align: left;
    font-size: 14px;
    a, a:visited, a:link, a:active{
            text-transform: capitalize;
          }
  }
  .service-core-item-fr {
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 10%;
    text-align: left;
    font-size: 14px;
    a, a:visited, a:link, a:active{
            text-transform: none;
          }
  }

.services-core-header {
  font-size: 15px;
    z-index: 1;
    background-color: #fff;
    top: -15px;
    position: absolute;
    width: 30%;
    left: 35%;
    color: #18315f;
    font-weight: bold;
    @media (max-width: $screen-md){
        width: 35%;
        left: 33%;
    }
    @media (max-width: $screen-sm){
        width: 70%;
        left: 15%;
    }
    @media (max-width: $screen-xs){
        background-color: $white;
        width: 60%;
        left: 20%;
    }
}

  .service-large-item {
    padding: 10px 20px 10px 20px;
  }
}


.services-container {
  margin-bottom: 3%;
  padding: 10px 20px 10px 20px;
  width: 90%;
  display: table;
  @media (max-width: $screen-xs){
      width: 100%;
  }


  .service-large-item {
    display: inline-block;
    padding: 60px 30px;
    height: auto;
    min-height: 500px;
    
    @media (max-width: $screen-lg){
        min-height: 550px;
    }
    
    @media (max-width: $screen-md){
        margin-bottom: 20px;
        min-height: 0px;
    }

    .service-large-icon {
      padding: 10px 0px 10px 0px;
    }

    .service-large-title {
      font-size: 26px;
      margin: 10px 0px 0px 0px;
      color: #18315f;
      text-transform: capitalize;
    }

    .service-large-description {
      font-size: 16px;
      font-family: 'Crimson Text', serif;
      color: $gray-dark;
      margin: 10px 0px 30px 0px;
    }
  }
}

.services-wrapper {
  width: 70%;
  margin-top: 5%;
  @media (max-width: $screen-sm){
      width: 90%;
  }
}
