@font-face {
  font-family: MuliFont;
  src: url('../fonts/muli-webfont.eot'); /* IE9 Compat Modes */
  src: url('../fonts/muli-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/muli-webfont.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/muli-webfont.woff') format('woff'), /* Pretty Modern Browsers */
  url('../fonts/muli-webfont.ttf')  format('truetype'), /* Safari, Android, iOS */
  url('../fonts/muli-webfont.svg#svgFontName') format('svg'); /* Legacy iOS */
}


body {
  font-family: MuliFont, sans-serif;
  color: #18315f;
}

.font-crimson {
    font-family: 'Crimson Text', serif;
    padding-left: 3px;
    font-size: 16px;
}

.banner-img-wrapper {
  max-height: 450px;
  max-width: 1400px;
  width: 100%;
  position:relative;
  text-align: center;
  overflow: hidden;
  margin: 0 auto;
  img{
    width: 100%;
    margin: 0 auto;
    @media ( max-width: $screen-xs ){
      display: none;
    }
  }
}

div.fw-callout-wrapper {
  margin-top: 15px;
  width: 100%;
  background-color: #99a4b6;
  min-height: 0px;
    @media (max-width: $screen-md){
      height: auto;
  }
  

  .fw-callout-img-container {
    height: 360px;
    overflow:hidden;
    padding: 0px;
    position: relative;
    @media (max-width: $screen-md){
        height: auto;
    }
    .fw-img-wrap{
        margin-right: 15px;
        margin-top: 15px;
        margin-bottom:15px;
        min-height: 360px;
        background-color: $blue-medium;
        z-index: 10;
        position: relative;
        overflow: hidden;
        @media (max-width: $screen-md){
            min-height: 0px;
            margin-right: 0px;
        }
        img{
            position: absolute;
            z-index: 1;
            @media (max-width: $screen-lg){
                left: 0px;
            }
            @media (max-width: $screen-md){
                max-width: 100%;
                width: 100%;
                height: auto;
                position: relative;
            }
        }
    }
  }

  .fw-callout-text-container {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
    color: white;
    border: 1px solid $white;
    text-transform: uppercase;
    min-height: 345px;
    padding: 40px;
    @media (max-width: $screen-md){
        margin-top: 5px;
        min-height: 0px;
    }
  }
}

.topAd{
  .fw-callout-wrapper{
    .fw-callout-text-container{
      max-height: 180px;
      min-height: 180px;
      @media (max-width: $screen-md){
        max-height: none;
      }
    }
    .fw-callout-text-container{
      padding: 10px;
      @media (max-width: $screen-md){
        margin: 15px 0px;
      }
    }
    .fw-callout-img-container{
      min-height: 195px;
      max-height: 180px;
      @media(max-width: $screen-md){
        margin-bottom: 15px;
        max-height: none;
      }
      .fw-img-wrap{
        @media (max-width: $screen-md){
          margin: 0px;
          margin-top: 15px;
        }
        &.right{
          margin: 15px 0px 15px 15px;
        }
        &.left{
          margin: 15px 15px 15px 0px;
        }
      }
    }
  }
}
a.btn {
  color: white;
  font-weight: bolder;
}

.btn-orange {
  color: white;
  background-color: $peach;
  border-radius: 0px;
  padding: 12px 50px;
}

.orange, a.orange {
   color: #c06426;
    a:hover, a:visited{
      color: #c06426;

   }

}

div.allfaqs {

  .orange, a.orange {
   color: #c06426;
   padding:30px 0 30px;
    
   }
}

.white {
  color: white;
}

hr.orange {
  border-color: #c06426;
}

.dark-blue {
  color: #8898b1;
  font-size: 20px;
}

.light-blue {
  background-color: #e6ebee;
}

.animate {
  //opacity: 0;
}

.text-normal {
  text-transform: none;
}

.language {
  float: right;
  div#lang_sel_list {
    float: right;
    li {
      border-right: 1px solid #cdcdcd;
    }
    li:last-child {
      border-right: none;
    }
    li a{
      padding-right: 6px;
      background: transparent;
    }
    ul > li.icl-fr > a {
      color: #c06426;
    }
  }
}

.post-wrapper {
  .post-banner-wrapper{
    width: 100%;
    height: 200px;
    overflow: hidden;
    top: -50%;
  }

  .post-details-wrapper{
    position: relative;

    .post-details-box {
      margin: 0 auto;
      width: 80%;
      z-index: 1;
      background-color: white;

      @media(max-width: $screen-md){
        width: 90%;
      }
      
      @media (max-width: $screen-sm){
          width: 100%;
      }

      .entry-content {
        margin-top: 2%;
      }
    }
  }
}

.section {
  background: url('../images/section_page.png') repeat center;
  background-size: contain;
  width: 100%;
  height: 69px;
  z-index: 0;
  position: absolute;
  top: -46px;
}

/*.normal-width {
  width: 15%;
}

.faq-width {
  width: 20%;
}*/

.small {
  font-size: 14px;
}

.vr {
  border-left: 1px solid #c06426;
  height: 100%;
  padding-left: 2%;
  padding-right: 2%;
  color: #18315f;
}


div.banner-wrapper{
  .font-crimson {
    font-family: 'Crimson Text', serif;
    font-size: 17px;
    color: #272727;
  }
  .orange{
    font-size: 16px;
  }
  a{
    color: $orange;
  }
}
