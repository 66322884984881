div.articlesCategoryWrapper {

  .categoryBox {
    background-color: #fcf8ed;
    padding: 20px;
    margin-bottom: 20px;


    .category-header {
      font-size: medium;
      color: #18315f;
    }

    .category-list {
      text-align: left;
      color: #c06426;


      ul li.categories {
        text-transform: none;
        margin-left: 15px;
        color: #18315f;
       
        ul{
            display: block;
            margin-top: 10px;
        }
        li {

            list-style-position: inside;
            display: list-item;
            padding-top: 2.5%;
            padding-bottom: 2.5%;
            color: $peach;
            padding-left: 20px;

          @media (min-width: $screen-sm) and (max-width: $screen-md){
            padding-left: 0px;
          }

            a, a:visited, a:link, a:active{
             color: #c06426;
             text-transform: none;
             position:relative;
             padding-left: 20px;
              display: block;
           }

            a::before{
                content: ' ';
                @include orange-caret( $peach );
                position: absolute; 
                left: 0px;
                bottom: 5px;
            }

        }
       
      }
    }
    
  }

  .archiveBox {
    margin: 20px;
    
    span:first-child{
        margin-bottom: 10px;
        display: block;
    }

    .archive-list {
      text-align: left;
      ul{
          padding-left: 20px;
      }

      ul li {
        display: list-item;
        padding-top: 2.5%;
        padding-bottom: 2.5%;
        color: $orange;
        position: relative;
        padding-left: 20px;
        a{
          color: $orange;
        }
      }
      
      li::before{
            @include orange-caret( $peach );
            content: ' ';
            position: absolute;
            left: 0px;
            bottom: 13px;
        }
    }
  }
}

div.post-wrapper {
  //margin-top: 30px;
  @media (max-width: $screen-sm){
    margin-top: 0px;
  }
  .wp-editor-content{
    font-family: 'Crimson Text', serif;
    color: $gray;
    font-size: 17px;
    line-height: 30px;
    li{
      margin-bottom: 10px;
    }
  }
  div.banner-img-wrapper {
    img {
      width: 100%;
      height: 100%;
    }
  }
}

div.blogWrapper {
  .blogItem {
    margin-bottom: 25px;
    height: auto;
    min-height: 150px;
    
    @media (min-width: $screen-xs) and (max-width: $screen-sm){
        margin: 20px 0px;
    }
    
    @media (max-width: $screen-xs){
        margin: 10px 0px;
        display: block;
        margin-bottom: 30px;
        clear: both;
    }
    
    .row{
        margin-bottom: 45px;
        
        @media (max-width: $screen-sm){
            margin-bottom: 10px;
        }
    }
    
    p, span{
        text-transform: initial;
    }

    p{
      font-family: 'Crimson Text', serif;
      color: $gray-dark;
      font-size: 16px;
    }

    p.read-more{
      a{
        font-family: MuliFont, sans-serif;
        font-size: 14px;
      }
    }

    .categoryBlogCollapse {
      display: block;
    }

    .blogImage {
      position: relative;
      left: 0;
      top: 0;
      img{
            border: 2px solid $blue-light;
            max-width: 100%;
            height: auto;
            @media (max-width: $screen-xs){
                display: none;
            }
      }
    }

    .blogTypeImage {
      display: inline-block;
      margin: auto;
      width: 40px;
      height: 40px;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 15px;
      background-repeat: no-repeat;

      @media (max-width: $screen-xs){
        width: 25px;
        height:25px;
        background-size: contain;
        display: block;
        margin-right: 20px;
      }
    }
    
    .blogDescription{
      p{
        font-family: 'Crimson Text', serif;
        font-size: 16px;
        color: $gray-dark;
      }
        @media (max-width: $screen-xs){
            @include make-xs-column(10);
            margin-left: 40px;
        }
    }

    .read-more{
      @media (max-width: $screen-xs){
        margin-bottom: 30px;
      }
    }

    .cat-meta-wrap{
      @media (max-width: $screen-xs){
        display: none;
      }
    }
    .small {
      width: 30px;
      height: 30px;
    }

    .video {
      background: url('../images/blog_video.png') no-repeat center;
      background-size: contain;
    }

    .page {
      background: url('../images/blog_page.png') no-repeat center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    
    .read-more{
        a{
            color: $orange;
            position:relative;
        }
      .fa{
        margin-left: 5px;
        opacity: 0.7;
      }
        a::after{
            //@include orange-caret( $peach );
            position: absolute;
            left: 90px;
            top: 3px;
            content: ' ';
        }
      .french-arrow{
        &::after{
          left: 160px !important;
        }
      }
    }
  }

}

div.faqWrapper {
  .faqHeaderBox {
    text-align: center;
    margin-bottom: 2%;
  }
}

.post-details-wrapper{
  .post-details-box{
    padding: 15px 40px;
    @media (max-width: $screen-xs){
      padding: 0px;
    }
    header{
      position: relative;
      img{
        position: absolute;
        top:-15px;
        left: -40px;
        max-width:30px;
        height: auto;
        @media (max-width: $screen-xs){
          display:none;
        }
      }
    }
    .lead{
      margin-top: 20px;
      margin-bottom: 0px;
      display: block;
    }
  }
}

.entry-content{
  color: $gray;
  .date{
    margin: 10px 0px 30px 0px;
  }
}

.social-buttons-wrap{
  width: 100%;
  font-size: 18px;
  @media (max-width: $screen-md){
    width: 90%;
  }
  li{
    display: inline-block;
    float: left;
    margin-right: 20px;
    text-align:center;
    @media (max-width: $screen-md){
      display: block;
      float: none;
      clear: both;
      margin: 0 auto;
      margin-bottom: 10px;
    }
    .fa{
      padding-left: 0px !important;
      padding-right: 10px !important;
    }
    a{
      text-transform: lowercase !important;
      @media (max-width: $screen-md){
        display: block;
        margin: 0 auto;
        width: 80%;
      }
    }
    a, .fa{
      padding: 10px 20px;
      font-weight: bold;
      color: $white;
    }
    a:hover{
      text-decoration: underline;
    }
  }
  li:hover{
    cursor: pointer;
  }
  li:last-child{
    margin-right: 0px;
  }
  .facebook-share{
    background-color: $blue-facebook;
  }
  .linkedin-share{
    background-color: $blue-linkedin;
  }
  .twitter-share{
    background-color: $blue-twitter;
  }
  .email-share{
    background-color: $peach;
  }
  .print-share{
    background-color: $gray-print;
  }
}

.about-kerr-wrap{
  border-top: 2px solid $blue-light;
  border-bottom: 2px solid $blue-light;
  padding: 30px 0px;
  width: 100%;
  font-size: 18px;
  font-family: 'Crimson Text', serif;
  color: $gray;
  .about-title{
    font-weight: 700;
    @media (max-width: $screen-sm){
      margin-top: 10px;
    }
  }
  img{
    border: 1px solid $brand-primary;
    padding: 20px;
    @media (max-width: $screen-sm){
      margin: 0 auto;
    }
  }
}

.social-buttons-wrap, .about-kerr-wrap{
  @include blog-width();
}

.category-title{
  text-align:center;
  font-size: 20px;
  color: lighten( $brand-primary, 20%);
}