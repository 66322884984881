ul.faq li {
  display: list-item;
  list-style:none;
}

ul.faq li {
  padding: 20px;
}

ul.faq li.q {
  background-color: #e6ebee;
  font-weight: bold;
  font-size: 15px;
  border-bottom: 1px #ddd solid;
  cursor: pointer;
}

ul.faq li.a {
  display: none;
  color: $gray-dark;
}

.rotate {
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}


.faq{
    .a{
        ul{
            padding-left: 15px;
        }
        li{
            list-style: initial;
            padding: 5px;
        }
    }

}

.faq-tax-wrap{
  .faq-title{
    text-align:center;
    margin: 30px 0px;
    @media (max-width: $screen-xs){
      font-size: 24px;
    }
  }
}