div.banner {
  background: #e6ecee;
  color: #18315f;
  padding-top: 32px;
  padding-bottom: 23px;
  text-align: center;
  height: 155px;
  
  @media (max-width: $screen-xs){
      display: none;
  }

  .social {
    @include social-icons();
  }
  .language{
    @media (max-width: 530px){
      display: none;
    }
  }
  .company{
    @media (max-width: 530px){
      width: 100%;
      text-align:center;
    }
  }
}

.linkedin_logo{
  display: inline-block;
  margin: auto;
  width: 40px;
  height: 40px;
  background: url('../images/linkedin.png') no-repeat center;
  background-size: contain;
}

.youtube_logo{
  display: inline-block;
  margin: auto;
  width: 40px;
  height: 40px;
  background: url('../images/youtube.png') no-repeat center;
  background-size: contain;
}

.brand-logo{
  display: block;
  text-align: center;
  width: 230px;
  height: 100px;
  margin: auto;
  background: url('../images/EngWebLogo.png') no-repeat center;
  background-size: contain;
  @include hide-text();
}
.brand-logo-fr {
  display: block;
  text-align: center;
  width: 230px;
  height: 100px;
  margin: auto;
  background: url('../images/FrWebLogo.png') no-repeat center;
  background-size: contain;
  @include hide-text();
}

.header-text {
  font-size: 10px;
  margin-top: 0px;
}

.social {
  float: left;
}

.company {
  display: inline-block;
}



.header-image {
  padding-bottom: 10px;
}

hr {
  border: none;
  border-top: 2px solid #9ba3b6;
  border-bottom: 2px solid #9ba3b6;
  padding: 0px;
  margin: 0px;
}