.carousel.slide{
  max-width: 100%;
  overflow: hidden;
}
.carousel-inner{
  width: 100%;
  position:relative;
  margin: 0 auto;
  height: auto;
}

.carousel-caption{
  top: 1%;
  text-align: left;
  text-shadow: 0 0px 0px;
  width: 363px;

}

.carousel-img {
  width: 100%;
}

.carousel-description {
  color: #18315f;
  font-size: xx-large;
}

#homeCarousel{
  @media (max-width: $screen-xs){
    display: none;
  }
}

#homeCarousel, #companyCarousel {
  .carousel-indicators{
    bottom: 30px;
    li {
      border-radius: 0;
      margin-right: 1%;
      border-color: #98a5b6;
      margin-right: 8px;
    }

    .active {
      padding: 2px;
      background-color: #98a5b6;
      background-clip: content-box;
    }
  }

  .carousel-control {
    opacity: 1;
    i{
      text-shadow: 0 0px 0px;
      position: absolute;
      top: 50%;
      color: #98a5b6;
    }
    i.fa-circle {
      color: white;
      position: absolute;
      top: 50%;
    }

    .fa{
      color: $white;
      background-color: $blue-medium;
      padding: 10px 10px 10px 13px;
      border-radius: 40px;
      font-size: 20px !important;
    }

    .size {
      font-size: 2em;
    }
    .size-background {
      font-size: 1.5em;
    }

    .left, .right, .left-background, .right-background{
      @media (max-width: $screen-xs){
        display: none;
      }
    }

    .left, .left-background{
      right: 20px;
    }

    .right, .right-background{
      left: 20px;
    }

    .left {
      margin-left: -80px;
    }
    .left-background {
      margin-top: 9px;
    }

    .right {
      margin-left: -1px;
    }
    .right-background {
      margin-top: 8px;
    }
  }
}

.carousel-control.left,.carousel-control.right {background-image:none;}

#companyCarousel {
  padding: 15px 15px;
  margin: 15px 0px;
  max-width: 100%;
  overflow: hidden;
  
  @media (max-width: $screen-md){
      display: none;
  }

  .carousel-control {
    width: 10%;
    margin-top: -40px;
  }

  .carousel-inner {
    max-width: 80%;
    margin: 0 auto;
    height: auto;
  }

  a.left > i {
    margin-left: 25% !important;
  }

  a.right > i {
   // margin-left: -50% !important;
  }

  .item {
    padding-left: 2%;
    padding-right: 2%;
  }
}

.no-top-border-carousel{
    .carousel{
        border-top: none !important;
    }
}