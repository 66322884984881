@mixin social-icons(){
    a {
      float: left;
      margin-left: 15px;
    }
    .fa{
        font-size: 32px;
    }
    .fa-linkedin-square{
        color: #0385b7;
    }
    .fa-youtube-square{
        color: #ee4d43;
    }
}

@mixin orange-caret( $color ){
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 0 5px 10px;
    border-color: transparent transparent transparent $color;
}

@mixin blog-width(){
    width: 80%;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 40px;
    @media (max-width: $screen-md){
        width: 90%;
    }
    @media (max-width: $screen-xs){
        width: 100%;
    }
}