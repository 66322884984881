.subfooter {
  width: 100%;
  background-color: #d8e4e7;
  display: block;
  padding-bottom: 2%;
  border-bottom: 3px solid $blue-medium;
}

.map{
  padding-top: 40px;
  text-align:center;
  margin-top: 70px;
  @media (max-width: $screen-md){
      margin-top: 0px;
  }
  a, a:hover {
    text-transform: lowercase;
  }
}

.centerMap {
  display: block;
  float: none;
  margin: 0 auto;
  width: 100%;
}

img.map-img{
  margin: 15px 0 15px ;
  border:solid #ffffff 2px;
}


.map .map-location {
  display: inline-block;
  text-align: center;
  color: #18315f;

    @media (max-width: $screen-sm){
        margin-top: 20px;
    }

    img{
        border:2px solid white;
        margin: 0 auto;
        margin-top: 10px;
        margin-bottom: 10px;
        @media (max-width: $screen-xs){
            display: none;
        }
    }

    .location{
        font-size: 16px;
    }

}



.map .contactWrapper {
  text-align: left;
  border: 1px;
  border-color: #d8e4e7;
  padding: 0px;
}

.contactWrapper.contact-us{
    border: 3px solid $blue-medium;
    @media (max-width: $screen-xs){
        margin: 0px;
        margin-bottom: 20px;
        width: 100%;
    }
    img{
        margin-top: 0px;
    }
    .ninja-forms-cont {
        .field-wrap.text-wrap {
            width: 100%;
            max-width: 95%;
            margin-right: 15px;
            @media (max-width: $screen-xs){
                max-width: 100%;
            }
        }
      .field-wrap.textarea-wrap{
        margin-left: 10px;
        max-width: 95%;
      }
        input[type="submit"]{
            background-color: $peach !important;
            font-weight: bold;
            font-size: 14px;
            padding: 15px 40px;
        }
        input[type="text"], textarea{
            padding: 5px;
        }
    }

    .contactFormWrapper{
        @media (max-width: $screen-xs){
            padding: 30px;
        }
    }
}

.contact-subfooter{
    .newsletter{
        width: 100%;
    }
}

.map .mapDetailWrapper {
  padding: 10% 15% 10% 15%;
}

.map .contactFormWrapper {
  background-color: #99a4b6;
  width: 100%;
  padding: 5%;
}

.newsletter {
  margin-top: 40px;
  background-color: #99a4b6;
  color: white;
  padding: 7px;
  @media (max-width: $screen-sm){
      width: 100% !important;
  }
  .newsletter-inner{
      padding: 15px 30px;
      border: 1px solid $blue-light;
      @media (max-width: $screen-xs){
          padding: 20px 30px 10px 30px;
      }
    .form-group{
      margin-bottom: 0px;
    }
    input[type="text"]{
      padding: 3px;
      font-size: 14px;
    }
  }
  input[type="submit"] {
    background-color: transparent;
    color: white;
    border: 1px solid white;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 2%;
    padding-bottom: 2%;
  }
  input[type="text"]{
      padding: 10px;
  }
}

.full {
  width: 80%;
  float: none;
}

.center {
  margin: 0 auto;
  margin-top: 40px;
}

.newsletter .newsletter-header {
  display: inline-block;
  margin-bottom: 20px;
  .img-wrap{
      display: inline-block;
      float: left;
      @media (max-width: $screen-sm){
          display: none;
      }
    img{
      max-width: 140px;
      margin-top: 20px;
      @media (max-width: $screen-lg){
        max-width: 100px;
      }
      @media (max-width: $screen-md){
        max-width: 160px;
      }
    }
  }
}

.newsletter .newsletter-header .newsletter-detail {
  float: right;
  width: 65%;
  font-size: 14px;
  @media (max-width: $screen-sm){
      float: none;
      display: block;
      width: 100%;
      text-align:center;
  }
  .title{
      text-transform: uppercase;
      font-size: 18px;
      display: block;
      margin-left: 30px;
      @media (max-width: $screen-xs){
          margin-left: 0px;
      }
  }
}

.newsletter {
  .nf-field-container {

    @media (max-width: $screen-xs){
      max-width: 100%;
      float: none;
      margin-left: 0;
    }
    max-width: 45%;
    width: 100%;
    float: left;
    margin-right: 20px;
    //margin-left: 10px;
    margin-bottom: 20px;
    max-height: 28px;
    clear: unset;

  }

  .spam-wrap {
    input {
      width: 100% !important;
    }
  }
  .nf-field-container.submit-container {
    float: none;
    display: block;
    clear: left;
    /* width: 100%; */
    max-width: none;
    text-align: center;

    input[type=button] {
      width: auto;
      background-color: transparent;
      color: #fff;
      border: 1px solid #fff;
      padding-left: 10%;
      padding-right: 10%;
      padding-top: 2%;
      padding-bottom: 2%;
      text-transform: uppercase;
      font-weight: 700;
      margin: 0 auto;
    }
  }

  input.ninja-forms-field::placeholder {
    font-size: 14px;
    color: #444;
  }

  .field-wrap {
    display: block;
  }
}

.nf-form-errors {
  .nf-error-field-errors {
    display: none;
  }
}

.nf-error, .nf-before-form-content {
  display: none;
}

#nf-form-7-cont{
  .submit-wrap {
    justify-content: center;

    input[type=button] {
      background-color: #e8b797;
      font-weight: 700;
      color: #fff;
      border: 0 solid transparent;
      padding: 15px 40px;
    }
  }
}

#nf-form-9-cont {
  .submit-wrap {
    justify-content: center;

    input[type=button] {
      background-color: #e8b797;
      font-weight: 700;
      color: #fff;
      border: 0 solid transparent;
      padding: 15px 40px;
    }
  }
}

.ninja-forms-cont {
  margin-left: -15px;
  margin-right: -15px;
  .field-wrap {
    &.text-wrap {
      max-width: 45%;
      width: 100%;
      float: left;
      margin-right: 10px;
      margin-left: 10px;
      @media (max-width: $screen-xs){
          max-width: 100%;
          float: none;
          margin-left: 0px;
      }
    }
    &.submit-wrap{
        text-align:center;
        @media (max-width: $screen-xs){
            margin-bottom: 0px;
        }
        input[type="submit"]{
            text-transform: uppercase;
            font-weight: bold;
            margin: 0 auto;
            @media (max-width: $screen-xs){
                width: 100%;
            }
        }
    }
  }
}

.ninja-forms-cont {
  -webkit-column-count: 1;
  -moz-column-count: 1;
  column-count: 1;

  #nf_submit_7 {
    text-align: center;
    input[type="submit"] {
      background-color: $peach;
      font-weight: bold;
      color: white;
      border: 0px solid transparent;
      padding: 15px 40px;
    }

  }
}

.ninja-forms-cont {
  -webkit-column-count: 1;
  -moz-column-count: 1;
  column-count: 1;

  #nf_submit_9 {
    text-align: center;
    input[type="submit"] {
      background-color: $peach;
      font-weight: bold;
      color: white;
      border: 0px solid transparent;
      padding: 15px 40px;
    }

  }
}

input {
  color: #444444;
}

.copyrightCollapse {
  display: block;
}

.copyright {
  display: inline;
  vertical-align: middle;
  font-size: 12px;
  font-family: 'Crimson Text', serif;
  color: $gray;
}

.footer-language-container {
  display: inline;
  span {
    margin-top: -1%;
  }
  span > i:first-child {
    font-size: 2em;
    cursor: pointer;
  }
}

.backToTop-triangle-margin {
  margin-left: -50%;
}

footer{
    border-bottom: 3px solid $blue-medium;
    @media (max-width: $screen-sm){
        border-bottom: none;
    }
    nav.navbar{
        border-top: none;
        border-bottom: none;
    }
    hr{
        display: none !important;
    }
  .brand-logo{
    max-height: 40px;
    margin: 10px 0px;
    margin-left: -15px;
    width: auto;
    @media (max-width: $screen-md){
      margin: 0 auto;
      margin-top: 20px;
    }
  }
  .navbar-header{
    .alignleft{
      @media (max-width: $screen-md){
        float: none;
        margin: 0 auto;
      }
    }
  }
    .footer-menu{
        text-align:center;
        margin: 20px 0px;
        @media (max-width: $screen-sm){
            display: none;
        }
        #menuNav{
            margin-top: 0px !important;
        }
        ul{
           margin: 0 auto;
           text-align:right;
           float: right;
          display: inline-block;
          @media (max-width: $screen-md){
            text-align:center;
          }
           li{
               margin: 0 auto;
               float: none;
               display: inline-block;
           }
           .dropdown-menu, .caret{
               display: none;
           }
        }
    }
}

.footer-menu-wrap{
  max-width: 1200px;
}
.copyright-wrap{
    margin-top: 20px;
    padding: 15px;
    @media (max-width: $screen-sm){
      text-align:center;
    }

  .social{
    display: block;
    font-size: 26px;
    width: 100%;
  }

    .reshift{
      font-size: 10px;
    }

    .footer-language-container{
      .fa{
        margin-top: 1px;
      }
    }
    .footer-language-container, .language{
        @media (max-width: $screen-sm){
            display: none;
        }
    }
    .orange{
        font-size: 12px;
        margin: 10px 0px;
        a{
            text-transform: initial;
            color: $orange;
        }
        a:hover{
            text-decoration: underline;
        }
    }
    .topbutton{
        width: 100%;
        text-align:center;
        margin-top: 20px;
        padding: 30px 0px;
        background-color: $brand-primary;
        color: $white;
        @media (min-width: $screen-sm){
            display: none;
        }
    }

      .company {
    display: inline-block;
    img{
        max-width: 100%;
    }
    .brand-logo{
        @media (max-width: $screen-sm){
            margin-bottom: 20px;
        }
    }
  }

}
