.members-box {
  .member {
    text-align: center;
    height: 280px;
    img{
      border: 3px solid $blue-light;
      padding: 0px;
      margin-bottom: 15px;
    }
    .orange{
      font-size: 16px;
    }
    .text-muted.small{
      font-size: 12px;
      color: $brand-primary;
    }
    a{
      text-transform: none;
    }
  }
}

.modal.fade .modal-dialog {
  -webkit-transform: translate(0, 0%);
  -ms-transform: translate(0, 0%);
  transform: translate(0, 0%);

  .modal-header {
    border-bottom: none;
  }

  .modal-footer {
    border-top: none;
  }
  
  .modal-header-member{
      img{
          border: 2px solid $blue-medium;
          margin-bottom: 20px;
      }
  }
}

.background-linkedin {
  background-color: #0286b6;
  color: white;
  padding: 3px;

  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.admin-staff-wrap{
    padding: 5px;
    border: 3px solid $blue-medium;
    color: $brand-primary;
    position: relative;
    text-align:center;
    margin-bottom: 110px;
    @media (max-width: $screen-sm){
        max-width: 400px;
        margin: 0 auto;
        margin-bottom: 100px;
    }
    .admin-title{
        font-size: 15px;
        text-transform: uppercase;
        padding: 10px;
        background-color: $white;
        position: absolute;
        top: -20px;
        left: 37%;
        @media (max-width: $screen-sm){
            left: 25%;
            top: -15px;
        }
        @media (max-width: $screen-xs){
            width: 100%;
            position: relative;
            left: 0px;
            top: -29px;
        }
    }
    h5{
        margin-top: 30px;
        color: $orange;
        margin-bottom: 0px;
        @media (max-width: $screen-sm){
            margin-top: 20px;
        }
    }
}

.admin-support-inner{
    border: 1px solid $blue-medium;
    padding: 30px 60px 30px 0px;
    @media (max-width: $screen-sm){
        padding: 15px;
    }
}